<template>
  <v-card class="mx-auto" width="900">
    <v-card class="mx-auto" :loading="isSvgNull">
      <h3 v-if="svg" class="ma-3 success text-center">Выберите место, нажав по схеме вагона</h3>
      <div>
        <div class="chips-container justify-center">
          <v-chip
              v-if="!isSvgNull"
              v-for="seatType in seatTypes"
              :key="seatType.type"
              :style="{ backgroundColor: seatType.color, color: seatType.color === 'pink' ? 'black' : 'white' }"
              class="ma-1"
          >
            {{ seatType.text }}
          </v-chip>
        </div>
        <div v-for="(item, index) in svg" :key="`svg-${index}`" v-html="item" class="mt-3 svg-element"></div>
      </div>

      <h3 v-if="!svg" class="ma-3 error text-center">Отсутствует схема вагона, выберите место из списка ниже</h3>
    </v-card>

    <v-card-text>
      <h2 class="text-h6 mb-2">Свободные места:</h2>
      <v-text-field v-model="searchTerm" @input="onInput" label="Поиск по номеру места"></v-text-field>
      <v-sheet
          height="200px"
          class="overflow-y-auto"
      >
        <v-chip-group
            v-model="normalSelectedSeats"
            column
            multiple
        >
          <v-chip
              v-for="seat in filteredSeats"
              :class="getGenderClass(seat.genderType)"
              :key="seat.placeNumber"
              active-class="orange--text"
              class="ma-1"
              :value="seat.placeNumber"
              filter
              outlined
              :disabled="disabledChips"
              @click="toggleSeatSelection(seat.placeNumber); selectSeatInSvg(seat.placeNumber)"
          >
            {{ `Место ${seat.placeNumber} - ${seat.minPrice} ₽ ` }}
          </v-chip>
        </v-chip-group>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>
<script>
import InfoChip from "@/components/UI/InfoChip.vue";

export default {
  name: "CarInfo",
  components: {InfoChip},
  props: {
    car: {
      type: Object,
      require: true,
    },
    inputArray: {
      type: Array,
      default: () => [],
    },
    isAllow: {
      type: Boolean,
      default: true,
    },

    currentVagonIndex: {
      type: Number,
    }
  },

  data() {
    return {
      searchTerm: '',
      svg: null,
      disabledChips: false,
      normalSelectedSeats: [],
      selectedCarTrainNumber: "",
      selectedServiceClass: "",
      selectedCarNumber: "",
      selectedCarType: "",
      currentVagonFreePlaces: [],
      dataCar: [],
      freePlaces: [],
      genderComp: false,
      seatTypes: [
        {type: 'Female', color: '#ff00ea', text: 'Женское'},
        {type: 'Male', color: '#0095ff', text: 'Мужское'},
        {type: 'NoValue', color: 'green', text: 'Смешанное'}
      ],
    };
  },
  model: {
    prop: "inputArray",
    event: "change-seats",
  },
  watch: {
    normalSelectedSeats(arr) {
      this.$emit("change-seats", arr);
    },
    filteredSeats(arr) {
      console.log('test', arr)
    }
  },

  async mounted() {
    this.dataCar = this.car;
    this.freePlaces = this.dataCar.freePlaces.split(', ').map(String).sort((a, b) => a - b)
    this.svg = await this.railwaySchemeImage(this.dataCar.carSchemeId);

    this.updateSvgWithFreePlaces(this.currentVagonFreePlaces);
  },

  beforeDestroy() {
    const svgElements = document.querySelectorAll('#carInfo_' + this.currentVagonIndex);
    svgElements.forEach(svg => {
      svg.removeEventListener('click', this.getE);
    });
  },
  computed: {
    filteredSeats() {
      if (!this.searchTerm) {
        return this.dataCar.places;
      }
      return this.dataCar.places.filter(seat => seat.placeNumber.toString().startsWith(this.searchTerm));
    },
    isSvgNull() {
      return this.svg === null;
    },
  },

  methods: {
    getGenderClass(genderType) {
      switch (genderType) {
        case 'Male':
          this.genderComp = true;
          return 'gender-male';
        case 'Female':
          this.genderComp = true;
          return 'gender-female';
        case 'NoValue':
        default:
          return 'gender-novalue';
      }
    },
    updateSvgWithFreePlaces() {
      this.$nextTick(() => {
        const svgElements = document.querySelectorAll('#carInfo_' + this.currentVagonIndex);
        console.log('#carInfo_' + this.currentVagonIndex);
        svgElements.forEach(svg => {
          console.log(svg)
          svg.addEventListener('click', this.getE);
          const cls4Elements = svg.querySelectorAll('.cls-4');
          cls4Elements.forEach(element => {
            element.classList.remove('cls-4');
            element.classList.add('cls-3');
          });
          const circle = svg.querySelectorAll('[id^="Pol"]')
          circle.forEach(e => {
            e.remove()
          })
          const seatElements = svg.querySelectorAll('[id^="Seat"]');


          seatElements.forEach(seatElement => {
            // Извлекаем числовую часть из id элемента
            console.log(seatElement)
            const seatNumberMatch = seatElement.id.match(/\d+/);
            const seatNumber = seatNumberMatch ? parseInt(seatNumberMatch[0], 10) : null;


            // Преобразуем каждый элемент this.freePlaces в его числовую часть
            const numericFreePlaces = this.freePlaces.map(place => {
              const match = place.match(/\d+/);
              return match ? parseInt(match[0], 10) : null;
            });

            if (seatNumber !== null && numericFreePlaces.includes(seatNumber)) {
              const placeInfo = this.dataCar.places.find(place => parseInt(place.placeNumber, 10) === seatNumber);

              if (placeInfo && placeInfo.genderType === 'Male') {
                seatElement.style.stroke = '#0095ff'
                seatElement.style.strokeWidth = 8
              }
              if (placeInfo && placeInfo.genderType === 'Female') {
                seatElement.style.stroke = '#ff00ea'
                seatElement.style.strokeWidth = 8
              }
              seatElement.style.fill = 'green';

            } else {
              seatElement.style.fill = 'black';
            }
          });
        });
      });
    },

    selectSeatInSvg(seatNumber) {
      const svgElements = document.querySelectorAll('#carInfo_' + this.currentVagonIndex);
      svgElements.forEach(svg => {
        const seatElement = svg.querySelector(`#Seat${parseInt(seatNumber, 10)}`);
        if (seatElement) {
          if (this.normalSelectedSeats.includes(seatNumber)) {
            seatElement.style.fill = 'green';
          } else {
            // Место не выбрано, возвращаем обычное состояние
            seatElement.style.fill = 'orange';
          }
        }

      });
    },
    async onInput() {

      if (this.searchTerm.startsWith('це')) {
        this.searchTerm = 'цена:';
      }
    },
    toggleSeatSelection(seatNumber) {
      const normalizedSeatNumber = typeof seatNumber === 'string' ? parseInt(seatNumber, 10) : seatNumber;

      const objectIndex = this.normalSelectedSeats.findIndex(object => object.placeNumber === normalizedSeatNumber);

      if (objectIndex !== -1) {
        this.normalSelectedSeats.splice(objectIndex, 1);
      }

      this.$emit("update-selected-seats", this.normalSelectedSeats);
    },
    getE(e) {
      let clickedElement = e.target;

      // Ищем элемент схемы вагона, у которого id начинается с 'Seat'
      while (clickedElement && !clickedElement.id.startsWith('Seat')) {
        clickedElement = clickedElement.parentNode;
      }

      if (clickedElement && clickedElement.id.startsWith('Seat')) {
        const seatNumber = parseInt(clickedElement.id.slice(4)); // Получаем номер места из ID
        if (clickedElement.style.fill === 'black') {
          // Место занято, не делаем ничего
          return;
        }

        // Проверяем, есть ли уже такой номер места в массиве выбранных мест
        const seatIndex = this.normalSelectedSeats.findIndex(seat => parseInt(seat, 10) === seatNumber);

        if (seatIndex !== -1) {
          this.normalSelectedSeats.splice(seatIndex, 1);
          clickedElement.style.fill = 'green'; // Возвращаем обычное состояние
        } else {
          let seatNumberOrigin = this.filteredSeats.find(seat =>
              seat.placeNumber.startsWith(seatNumber.toString())
          );

          this.normalSelectedSeats.push(seatNumberOrigin.placeNumber.toString());
          clickedElement.style.fill = 'orange'; // Выделяем выбранное место
        }
      }
    },
    async railwaySchemeImage(schema) {
      const headers = {"Access-Control-Allow-Origin": "*"};
      let url = `${this.$enums.Endpoints.RailwayData.GetSchemeCar}?schemeId=${schema}`;

      try {
        this.disabledChips = true;
        if (schema) {
          this.normalSelectedSeats.push("loadingSvgFlag")
          let resp = await this.$axios.get(url, {headers: headers});
          /*
                    if (/\d[а-яА-Яa-zA-Z]/.test(this.dataCar.freePlaces)) {
                      this.isSvgNull = true;
                      return [];
                    }
          */

          return resp.data.map((svgString) => {
            let idSvg = `carInfo_${this.currentVagonIndex}`;
            let regex = /id="[^"]*"/;
            let dataname = /data-name="[^"]*"/g;
            return svgString.replace(regex, `id="${idSvg}"`).replace(dataname, `data-name="${idSvg}"`);
          });
        }
      } catch (e) {
        console.log("scheme - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response ? e.response.status : 'Unknown',
            Message: e.response ? e.response.data : 'No response data',
          },
        };
        return [];
      } finally {
        this.disabledChips = false
        this.normalSelectedSeats.pop()
      }
    },
  },
}
</script>
<style>
/* Устанавливаем фиксированный размер для SVG-элементов */
.svg-element svg {
  width: 900px;
  height: 200px;
}

.svg-element svg [id^="Seat"]:hover {
  cursor: pointer;
}

.gender-male {
  border-color: #0095ff !important;
}

.gender-female {
  border-color: #ff00ea !important;
}

.gender-novalue {

  border-color: grey !important;
}

.chips-container {
  display: flex;
  justify-content: center;
}
</style>

