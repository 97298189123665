<template>
  <v-container>
    <overlay-loader overlay :loading="initLoading"></overlay-loader>
    <!--Passengers info-->
    <v-row v-if="!initLoading && cars" justify="center">
      <v-stepper outlined vertical v-model="stepperStep">
        <v-stepper-step color="orange" :complete="stepperStep > 0" step="1">
          Выбор места
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-tabs center-active grow color="orange" :show-arrows="true" v-model="currentVagonIndex">
            <v-tab :disabled="totalSeats" v-for="(car, n) in cars" :key="car.carNumber">
              <span>{{ `${car.carNumber} ${car.carTypeName}` }}</span>
            </v-tab>

              <v-tab-item v-for="(car, n) in cars" :key="n">
                <car-info
                    :currentVagonIndex="currentVagonIndex"
                    :car="cars[n]"
                    v-model="normalSelectedSeats"
                    :isAllow="isAllowTakeSeat"
                    @seat-selected="handleSeatChange(cars[n].carNumber, $event)"
                    @change-seats="updateTotalSeats"
                    @update-selected-seats="updateSelectedSeats"
                ></car-info>
              </v-tab-item>
          </v-tabs>
          <v-btn :disabled="!normalSelectedSeats.length > 0" color="orange" v-if="oneway" @click="nextStep"
                 class="mx-2">
            Далее
          </v-btn>
          <div v-if="!oneway">
            <v-btn :disabled="!normalSelectedSeats.length > 0" color="orange" @click="nextStepOneWayFalse" class="mx-2">
              Далее
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-step color="orange" v-if="oneway"
                        :complete="stepperStep > 1" step="2">
          Данные пассажира
        </v-stepper-step>
        <v-stepper-content v-if="oneway" step="2">
          <v-col cols="12" md="12" order="0">
            <v-card class="px-5 py-16">
              <v-row justify="center">
                <h1>
                  <span class="orange--text">Данные</span>
                  пассажир<span v-if="passengers.length > 1">ов</span
                  ><span v-else>а</span>
                </h1>
              </v-row>
              <v-row>
                <v-tabs center-active grow color="orange" :show-arrows="true">
                  <v-tab v-for="(passenger, n) in passengersData" :key="n">
                    <span v-if="passenger.Name">{{ passenger.Name }}</span>
                    <span v-else>Пассажир {{ n + 1 }}</span>
                    <v-icon @click="removePassenger(n)">mdi-close</v-icon>
                  </v-tab>
                  <v-icon @click="addPassenger()">mdi-plus</v-icon>
                  <v-tab-item class="pa-10" v-for="(passenger, n) in passengersData" :key="n">
                    <!--                                        <v-btn color="orange" @click="autoFillPassengerData">
                                                              <v-icon left>mdi-account-circle</v-icon>
                                                              Заполнить данные автоматически
                                                            </v-btn>-->
                    <v-form class="" ref="contactInfo_" v-model="contactInfoValid">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                              outlined
                              color="orange"
                              label="Фамилия"
                              type="text"
                              v-model="passenger.lastName"
                              required
                              prepend-icon="mdi-account"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">

                          <v-text-field
                              outlined
                              color="orange"
                              label="Имя"
                              type="text"
                              v-model="passenger.firstName"
                              required
                              prepend-icon="mdi-account"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                              outlined
                              color="orange"
                              label="Отчество"
                              type="text"
                              v-model="passenger.middleName"
                              prepend-icon="mdi-account"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select
                              v-model="passenger.citizenship"
                              :items="citizenship"
                              value=""
                              label="Выберите гражданство"
                              prepend-icon="mdi-earth"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                              outlined
                              color="orange"
                              label="Гражданство"
                              type="text"
                              v-model="passenger.citizenship"
                              value=""
                              hint="RU - Россия, UA - Украина, KZ - Казахстан, BY - Беларусь"
                              required
                              prepend-icon="mdi-earth"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                              v-model="passenger.type"
                              :items="categories"
                              @change="updatePassengerTariffCode(passenger)"
                              label="Выберите категорию пассажира"
                              prepend-icon="mdi-account-group"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select
                              v-model="passenger.documentType"
                              :items="filteredDocTypes(passenger)"
                              label="Выберите тип документа"
                              prepend-icon="mdi-card-account-details"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                              outlined
                              color="orange"
                              label="Номер документа"
                              type="text"
                              v-model="passenger.documentNumber"
                              required
                              prepend-icon="mdi-card-account-details"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                              v-model="passenger.gender"
                              :items="genders"
                              label="Выберите гендер"
                              prepend-icon="mdi-gender-male-female"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                              outlined
                              color="orange"
                              label="Дата рождения"
                              type="date"
                              v-model="passenger.birthday"
                              required
                              prepend-icon="mdi-calendar"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                              outlined
                              color="orange"
                              min="2"
                              label="Телефон"
                              type="phone"
                              v-model="passenger.phone"
                              required
                              :rules="[val => validatePhone(val, passenger.citizenship === 'RU') || 'Номер телефона недействителен']"
                              prepend-icon="mdi-phone"
                          >
                          </v-text-field>

                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                              outlined
                              color="orange"
                              min="2"
                              label="Email"
                              type="email"
                              v-model="passenger.email"
                              required
                              :rules="emailRule"
                              prepend-icon="mdi-email"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </v-row>
            </v-card>
          </v-col>
          <v-btn color="orange" @click="stepperStep--" class="mx-2">
            К выбору мест
          </v-btn>
          <v-btn color="orange"
                 :disabled="!passengersData.every(passenger =>
         passenger.documentType &&
         passenger.documentNumber &&
         passenger.lastName &&
         passenger.firstName &&
         passenger.middleName &&
         passenger.birthday &&
         passenger.citizenship &&
         passenger.gender &&
         passenger.email)"
                 @click="stepperStep++"
                 class="mx-2">
            Далее
          </v-btn>

        </v-stepper-content>
        <v-stepper-step disabled="true" v-if="oneway" color="orange" :complete="stepperStep > 2" step="3">
          Бронирование
        </v-stepper-step>
        <v-stepper-step v-if="!oneway" color="orange" :complete="stepperStep > 2" step="3">
          Подтверждение места
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-col cols="12" md="12" order="1">
            <v-text-field
                v-if="oneway"
                outlined
                color="orange"
                label="Сбор"
                type="text"
                v-model="agentInfo.AffilateFee"
                value="0"
                required
            ></v-text-field>
            <v-row class="text-h4 mt-10">Товары</v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>
            <v-row v-if="seats" class="text-h6 mt-5">
              <v-col> Билеты туда x {{ this.seats }}</v-col>
              <v-col align="end">{{
                  this.priceThere.toFixed(2) + " РУБ"
                }}
              </v-col>
            </v-row>
            <v-row class="text-h6 mt-5">
              <v-col> Билеты x {{ this.totalSeats }}</v-col>
              <v-col align="end">{{
                  totalPrice.toFixed(2) + " РУБ"
              }}</v-col>
            </v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>
            <v-row class="text-h6 mt-5">
              <v-col v-if="!oneway">Итог (предварительный за места туда)</v-col>
              <v-col v-if="oneway">Итог (за все места и сбор)</v-col>
              <v-col align="end">{{
                  (+this.totalPrice + +this.priceThere + +this.agentInfo.AffilateFee).toFixed(2) + " РУБ"
                }}
              </v-col>
            </v-row>
            <v-row class="text-h6 mt-5">
              <v-col>
                <v-btn
                    v-if="oneway"
                  block
                  elevation="0"
                  color="orange"
                  :loading="bookLoading"
                  :disabled="!comparer"
                  @click="booking"
                >
                  Бронировать
                </v-btn>
                <v-btn
                    v-if="!oneway"
                    block
                    elevation="0"
                    color="orange"
                    :loading="bookLoading"
                    :disabled="!comparer"
                    @click="booking"
                >
                  Утвердить места,перейти к выбору второго сегмента
                </v-btn>
              </v-col>
            </v-row>
            <v-btn v-if="oneway" color="orange" @click="stepperStep--" class="mt-7 move">
              К данным
            </v-btn>
            <v-btn v-if="!oneway" color="orange" @click="stepperStep--, stepperStep--" class="mt-7 move">
              К местам
            </v-btn>
          </v-col>
        </v-stepper-content>
      </v-stepper>
    </v-row>

    <!--The flight is unavailable-->
    <v-row
      v-if="!cars && !initLoading"
      class="fill-height py-16 my-16"
      justify="center"
    >
      <v-col class="text-center">
        <v-btn icon @click="$router.go()">
          <v-icon x-large color="primary">la-refresh</v-icon>
        </v-btn>
        <div class="text--disabled">Something went wrong! try again.</div>
      </v-col>
    </v-row>

    <!--    &lt;!&ndash;show cart&ndash;&gt;
        <div v-if="payDialog" class="text-center">
          <v-dialog scrollable persistent width="50%" v-model="payDialog">
            <v-card>
              <v-card-title class="headline accent"> Корзина </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-row class="text-h4 font-weight-bold mt-10">Товары</v-row>
                      <v-row>
                        <v-divider></v-divider>
                      </v-row>
                      <v-row class="text-h6 mt-5">
                        <v-col cols="5"> Билеты x {{ this.totalSeats }}</v-col>
                        <v-col class="text-right text-h6 font-weight-bold">
                          {{ this.totalPrice.toFixed(2) + " РУБ" }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-divider></v-divider>
                      </v-row>
                      <v-row class="text-h4 font-weight-bold mt-5">
                        <v-col cols="4">Итог</v-col>
                        <v-col align="end">{{ this.totalPrice + this.agentInfo.AffilateFee + " РУБ" }}</v-col>
                      </v-row>

                      <v-row justify="end" class="mt-10">
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          large
                          :loading="cancelBookLoading"
                          color="orange"
                          :disabled="confirmLoading"
                          @click="railwayCancelBooking"
                        >
                          Отмена
                        </v-btn>
                        <v-btn
                          dark
                          elevation="5"
                          :loading="confirmLoading"
                          color="orange"
                          large
                          @click="payment"
                        >
                          Оплатить
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div v-if="payDialog" class="text-center">
          <v-dialog persistent width="50%" v-model="payDialog">
            <purchase-summary
                :value="trainSegment"
                :ticketsCount="totalSeats"
                @cancel="(val) => (payDialog = val)"
            >
            </purchase-summary>
          </v-dialog>
        </div>-->

    <error-modal v-model="hasErrors" :error="error"></error-modal>
  </v-container>
</template>

<script>
import CarInfo from '@/components/BuyPage/CarInfo.vue';
import ErrorModal from "@/components/UI/ErrorModal";
import OverlayLoader from "@/components/UI/OverlayLoader";
import RailwayPassengerInfo from "@/components/BuyPage/RailwayPassengerInfo";
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat";
import Validators from "@/mixins/Validators";
import PassengerHelper from "@/mixins/booking/PassengerHelper";
import TrainsList from "@/Pages/TrainsList.vue";
import {format} from "date-fns";
import {mapGetters} from "vuex";
import {genders, categories, citizenship, docTypes, passengerInfo, passengerTemplate} from "@/services/constants";


export default {
  name: "BookTrain",
  components: {
    RailwayPassengerInfo,
    ErrorModal,
    OverlayLoader,
    CarInfo,
    TrainsList,
  },
  mixins: [DateFormat, PassengerHelper, Validators, ApiCalls],
  props: {
    "origin": String,
    "train": String,
    "adults": Number,
    "kids": Number,
    "bbs": Number,
    "searchId": String,
    "trainNumber": String,
    "departureDateTime": String,
    "dateFrom": String,
    "arrCityText": String,
    "depCityText": String,
    "arrCityCode": String,
    "depCityCode": String,
    "src": String,
    "dest": String,
    "codeSrc": String,
    "codeDest": String,
    "oneway":
        {
          type: Boolean,
          required: true
        },
    "segmentOne": String
  },
  data() {
    return {
      passengerTemplate,
      passengerInfo,
      genders,
      categories,
      citizenship,
      docTypes,
      currentVagonIndex: 0,
      normalSelectedSeats: [],
      priceThere: this.$store.getters.getPrice(),
      isFirstSegment: false,
      trainSegment: [],
      seats: this.$store.getters.getSeats(),
      onewayBoolean: this.oneway,
      agentInfo: {
        AffilateFee: 0.0,
      },
      stepperStep: 1,
      contactInfoValid: false,
      validForms: [],
      hasErrors: false,
      error: { Code: "", Message: "" },
      readyToProceed: false,
      initLoading: true,
      bookLoading: false,
      cancelBookLoading: false,
      payLoading: false,
      confirmLoading: false,
      cancelLoading: false,
      payDialog: false,
      showUnavailableServices: false,
      currentPass: 0,
      seatsCars: [],
      menus: Array(this.passCount).fill(false),
      maxDate: new Date().toISOString().substr(0, 10),
      allowedDocs: [],
      passengers: [],
      passengersData: this.initializePassengersData(),
      countries: [],
      orderId: null,
      book: null,
      amount: null,
      email: "",
      phone: {
        type: String,
        default: "+7",
      },
      cars: [],
      activeTab: 0,
      car: [],
    };
  },
  async mounted() {
    this.initLoading = true;
    this.isFirstSegment = this.$store.getters.getTrainSegments().length > 0;
    if (this.isFirstSegment) {
      if (!Array.isArray(this.trainSegment)) {
        this.trainSegment = [];
      }
      this.trainSegment.push(this.$store.getters.getTrainSegments()[0]);
    }
    this.cars = await this.getCars(this.searchId, this.departureDateTime, this.trainNumber);
    this.cars = this.cars.filter(car => car.carType !== 'Baggage');
    this.passengersData = this.initializePassengersData();
    this.initLoading = false;


    this.cars.sort((a, b) => a.carNumber - b.carNumber);
    this.seatsCars = this.cars.map(car => ({
      vagon: car.carNumber,
      carType: car.carType,
      carTypeName: car.carTypeName,
      serviceClassName: car.serviceClass,
      selectedSeats: [],
      CarTrainNumber: car.carTrainNumber
    }));
  },

  computed: {

    currentVagon() {
      return this.cars[this.currentVagonIndex]
    },

    seatsFrom() {
      if (this.normalSelectedSeats.length > 0) {
        const places = this.normalSelectedSeats.map(seat => typeof seat === 'object' ? seat.placeNumber : parseInt(seat));
        return Math.min(...places);
      }
      return null;
    },
    seatsTo() {
      if (this.normalSelectedSeats.length > 0) {
        const places = this.normalSelectedSeats.map(seat => typeof seat === 'object' ? seat.placeNumber : parseInt(seat));
        return Math.max(...places);
      }
      return null;
    },
    selectedCarNumber() {
      return this.currentVagon.carNumber;
    },
    selectedServiceClass() {
      return this.currentVagon.serviceClass;
    },
    selectedCarType() {
      return this.currentVagon.carType;
    },
    totalPrice() {
      return this.getSumSelectedSeatsPrices(this.getSelectedSeatsPrices(this.normalSelectedSeats));
    },
    totalSeats() {
      return this.normalSelectedSeats.length;
    },
    ...mapGetters(['getTrainSegments', 'getPrice', 'getSeats']),
    isAllPassengerDataFilled() {
      return this.passengersData.length === this.totalPassengers &&
          this.passengersData.every(passenger =>
              passenger.documentType &&
              passenger.documentNumber &&
              passenger.lastName &&
              passenger.firstName &&
              passenger.birthday &&
              passenger.citizenship &&
              passenger.gender &&
              passenger.type &&
              passenger.email &&
              passenger.phone
          );
    },
    ss() {
      let selectedSeats = this.getSelectedSeats();
      this.updateStorePrices();
      return selectedSeats;
    },
    isAllowTakeSeat() {
      return this.passengers.length > this.ss.length;
    },
    comparer() {
      this.readyToProceed = this.valid && this.contactInfoValid;
      if (!this.onewayBoolean && this.totalSeats > 0) {
        return this.readyToProceed = true;
      }
      return this.readyToProceed;
    },
    valid() {
      let condition = this.validForms.every((e) => e);
      return condition;
    },
    passCount() {
      return parseInt(this.adults) + parseInt(this.kids) + parseInt(this.bbs);
    },
  },
  methods: {
    updatePassengerTariffCode(passenger) {
      const selectedCategory = this.categories.find(category => category.value === passenger.type);
      if (selectedCategory) {
        passenger.tariffs.forEach(tariff => {
          tariff.code = selectedCategory.code;
        });
      }
    },
    removePassenger(index) {
      if (index >= 0 && index < this.passengersData.length) {
        this.passengersData.splice(index, 1);
      }
    },
    updateSelectedSeats(newSeats) {
      this.selectedSeats = newSeats;
    },
    updateTotalSeats(selectedSeats) {
      this.totalSeats = selectedSeats.length;
    },
    getSelectedSeats() {
      return this.seatsCars.flatMap(e => {
        if (e.selectedSeats.length > 0) {
          return e.selectedSeats.map(q => ({
            place: q.placeNumber,
            price: q.price,
            vagon: e.vagon,
          })).filter(item => item.place !== undefined);
        }
        return [];
      });
    },

    getPlaceRange(selectedSeats) {
      const placeNumbers = selectedSeats.map(seat => parseInt(seat.place));
      return {
        min: Math.min(...placeNumbers),
        max: Math.max(...placeNumbers)
      };
    },


    getTotalPrice(selectedSeats) {
      return selectedSeats.reduce((sum, seat) => sum + seat.price, 0);
    },

    updateStorePrices() {
      if (!this.oneway) {
        this.$store.dispatch('addPrice', this.totalPrice);
      }
      if (this.oneway && this.isFirstSegment) {
        this.$store.dispatch('addTotalPrice', this.totalPrice);
      }
    },

    addTrainSegment(newSegment) {
      this.$store.commit('addTrainSegment', newSegment);
      console.log("success save", newSegment);
    },

    savePrice(price) {
      this.$store.dispatch('addPrice', price)
    },

    saveSeats(seats) {
      this.$store.dispatch('addSeats', seats)
    },

    clearTrainSegments() {
      this.$store.dispatch('clearTrainSegments')
    },

    filteredDocTypes(passenger) {
      const categoryKey = this.getCategoryKey(passenger);
      const citizenshipKey = passenger.citizenship === 'RU' ? 'ru' : 'other';
      if (!categoryKey) return [];

      const allowedTypes = this.allowedDocs?.[categoryKey]?.[citizenshipKey] || [];
      const result = this.docTypes.filter(doc => allowedTypes.includes(doc.value));
      console.log('filteredDocTypes result:', result);
      return result;
    },

    getCategoryKey(passenger) {
      switch (passenger.type) {
        case '1':
          return 'adt';
        case '2':
          return 'chd';
        case '3':
          return 'inf';
        default:
          return null;
      }
    },
    handleSeatChange(carNumber, updatedSeats) {
      const carIndex = this.seatsCars.findIndex(car => car.vagon === carNumber);
      if (carIndex !== -1) {
        this.seatsCars[carIndex].selectedSeats = updatedSeats.filter(seat => !isNaN(seat.place));
      }
    },
    getSelectedSeatsPrices(normalSelectedSeats) {

      let car = this.currentVagon
      let selectedSeatsPrices = [];


      for (let place of car.places) {

        if (normalSelectedSeats.includes(place.placeNumber)) {
          selectedSeatsPrices.push(place.minPrice);
        }
      }

      return selectedSeatsPrices;
    },
    getSumSelectedSeatsPrices(selectedSeatsPrices) {
      return selectedSeatsPrices.reduce((total, price) => total + price, 0);
    },

    async getCars(searchId, dep, trainNumber) {
      try {
        let formattedDepTime = dep.replace(/ /g, '+');
        console.log()
        let url = `${this.$store.state.apiAvia + this.$enums.Endpoints.RailwayData.SelectCar}`;
        let body = {
          searchId: searchId,
          trainNumber: trainNumber,
          departureDateTime: formattedDepTime,
        };

        let resp = await this.$axios.post(url, body);
        this.cars = resp.data.data.items.filter(car => car.carType !== 'Baggage');
        console.log(this.cars)
        this.allowedDocs = resp.data.data.allowedDocTypes;

        return resp.data.data.items;
      } catch (e) {
        this.$store.commit('setError', {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data,
          },
        });
        throw e;
      }
    },

    nextStep() {
      for (let i = 0; i < this.passengers.length; i++) {
        this.passengers[i].Car = this.ss[i]
      }
      this.stepperStep++;
    },
    nextStepOneWayFalse() {
      for (let i = 0; i < this.passengers.length; i++) {
        this.passengers[i].Car = this.ss[i]
      }
      this.stepperStep++;
      this.stepperStep++;
    },
    async booking() {
      this.bookLoading = true;
      try {
        if (!this.isFirstSegment && this.oneway) {
          await this.handleOneWayBooking();
        } else if (this.onewayBoolean && this.isFirstSegment) {
          await this.handleReturnBooking();
        } else if (!this.onewayBoolean && !this.isFirstSegment) {
          await this.handleRoundTripBooking();
        } else {
          console.log("Unexpected condition");
        }
      } catch (e) {
        this.clearTrainSegments();
        console.error(e);
      } finally {
        this.bookLoading = false;
      }
    },

    getSeatNumbers() {
      let seatNumbers = [];

      if (this.seatsCars) {
        this.seatsCars.forEach(seatGroup => {
          if (seatGroup && seatGroup.selectedSeats) {
            seatGroup.selectedSeats.forEach(seat => {
              seatNumbers.push(seat.place);
            });
          }
        });
      }
      return seatNumbers;
    },

    sortSeatNumbers(seatNumbers) {
      return seatNumbers
          .flatMap(seatGroup => seatGroup && seatGroup.selectedSeats ? seatGroup.selectedSeats.map(seat => parseInt(seat.place)) : [])
          .sort((a, b) => a - b);
    },

    parseSegmentOne() {
      try {
        return this.isFirstSegment;
      } catch (e) {
        console.log(e);
        return this.isFirstSegment;
      }
    },

    async handleOneWayBooking() {
      let segmentThere = this.createSegment("RailwayThere");
      this.trainSegment.push(segmentThere);
      try {
        let response = await this.bookTrainOrder(
            this.trainSegment,
            this.passengersData,
            this.agentInfo.AffilateFee
        );
        console.log(response)
        this.payDialog = true
        await this.$router.push("/solded-tickets?type=railway");

      } catch (e) {
        this.clearTrainSegments()
        console.log(e);
      }
    },

    async handleReturnBooking() {
      let segmentBack = this.createSegment("RailwayBack");
      this.trainSegment.push(segmentBack);
      try {
        let response = await this.bookTrainOrder(
            this.trainSegment,
            this.passengersData,
            this.agentInfo.AffilateFee
        );
      } catch (e) {
        this.clearTrainSegments()
        console.log(e);
      }
      this.clearTrainSegments();
      await this.$router.push("/solded-tickets?type=railway");
    },

    async handleRoundTripBooking() {
      this.segmentOne = false;
      let segmentThere = this.createSegment("RailwayThere");
      this.trainSegment.push(segmentThere);
      console.log(this.trainSegment, "че там")
      this.addTrainSegment(this.trainSegment);
      this.savePrice(this.totalPrice);
      this.saveSeats(this.totalSeats);
      this.isFirstSegment = true;
      await this.$router.push(`/trs-list?src=${this.dest}&dest=${this.src}&codeSrc=${this.codeDest}&codeDest=${this.codeSrc}&date=${format(new Date(this.dateFrom), 'yyyy-MM-dd')}&adults=${this.adults}&kids=${this.kids}&bbs=${this.bbs}&oneway=true&segmentOne=${this.isFirstSegment}`);
    },
    defineGender() {
      let seatNumber = this.normalSelectedSeats[0]
      let seat = this.cars[this.currentVagonIndex].places.find(seat => seat.placeNumber === seatNumber);
      let genderType = seat.genderType
      switch (genderType) {
        case 'Female':
          return 'F';
        case 'Male':
          return 'M';
        default:
          return '';
      }
    },

    createSegment(segmentType) {
      return {
        searchId: this.searchId,
        segmentType: segmentType,
        trainNumber: this.trainNumber,
        departureDateTime: this.departureDateTime.replace(" ", "+"),
        carNumber: this.selectedCarNumber,
        carTrainNumber: this.seatsCars[0].CarTrainNumber,
        carType: this.selectedCarType,
        serviceClass: this.selectedServiceClass,
        setElectronicRegistration: 1,
        seatsFrom: this.seatsFrom,
        seatsTo: this.seatsTo,
        placeCount: this.totalSeats,
        cabinGender: this.defineGender(),
        oneSection: 1,
        bedding: 1,
        internationalServiceClass: "",
        comment: "",
        isBeddingSelectionPossible: 1,
        placeType: ""
      };
    },
    autoFillPassengerData() {
      this.passengersData = this.passengersData.map(passenger => ({
        ...passenger,
        documentType: 'C',
        documentNumber: '4514345778',
        lastName: 'Иванов',
        firstName: 'Иван',
        middleName: 'Иванович',
        birthday: '1990-01-01',
        citizenship: 'RU',
        gender: 'M',
        type: '1',
        email: 'test@example.com',
        phone: '+79999999999',
      }));
    },
    async payment() {
      this.confirmLoading = true;
      let successedBuy = false;
      let successedBlank = false;
      successedBuy = await this.railwayBuy();
      if (successedBuy) {
        successedBlank = await this.railwayBlank();
      }
      if (successedBuy && successedBlank) {
        this.$router.push("/success-railway");
      }

      this.confirmLoading = false;
      this.payDialog = false;
    },
    addPassenger(passengerData) {
      const passenger = {...passengerTemplate, ...passengerData};
      let selectedCategory = categories.find(category => category.value === passenger.type);


      if (this.oneway && !this.isFirstSegment) {
        passenger.tariffs.push({segmentType: "RailwayThere", code: 1});
      } else {
        passenger.tariffs.push({segmentType: "RailwayThere", code: 1});
        passenger.tariffs.push({segmentType: "RailwayBack", code: 1});
      }

      passenger.tariffs = passenger.tariffs.filter((tariff, index, self) =>
              index === self.findIndex((t) => (
                  t.segmentType === tariff.segmentType && t.code === tariff.code
              ))
      );

      this.passengersData.push(passenger);
    },
    initializePassengersData() {
      const totalPassengers = parseInt(this.adults) + parseInt(this.kids) + parseInt(this.bbs);
      this.passengersData = Array.from({length: totalPassengers}, () => {
        const passenger = {...passengerTemplate};

        if (this.oneway && !this.isFirstSegment) {
          passenger.tariffs.push({segmentType: "RailwayThere", code: 1});
        } else {
          passenger.tariffs.push({segmentType: "RailwayThere", code: 1});
          passenger.tariffs.push({segmentType: "RailwayBack", code: 1});
        }

        passenger.tariffs = passenger.tariffs.filter((tariff, index, self) =>
                index === self.findIndex((t) => (
                    t.segmentType === tariff.segmentType && t.code === tariff.code
                ))
        );

        return passenger;
      });

      return this.passengersData;
    },
  },
  watch: {
    currentVagonIndex(newVal, oldVal) {
      this.normalSelectedSeats = []
      if (newVal !== oldVal) {
        this.updateFlag = !this.updateFlag;

      }
    },
  },



};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.test {
  border: 2px solid red;
  margin: 5px;
}

.stepper {
  overflow: visible;
}

.localfix .v-slide-group__prev.v-slide-group__prev--disabled,
.localfix .v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}
</style>
