<template>
  <v-container fluid class="lighten-4">
    <v-row class="pt-2 mb-2" align="center" justify="center">
      <div class="pt-2 my-2 text-center">
        <div v-if="dateFrom" class="text-h5 text-sm-h4 text-md-h3 mt-5 font-weight-bold">
          Выбор сегмента туда
        </div>
        <div v-if="segmentOne" class="text-h5 text-sm-h4 text-md-h3 mt-5 font-weight-bold">
          Выбор сегмента обратно
        </div>
        <div class="text-h5 text-sm-h4 text-md-h3 mt-5 font-weight-bold">
          {{ src + "&nbsp;&nbsp;-&nbsp;&nbsp;" + dest }}
        </div>
      </div>
    </v-row>

    <!--
        <v-row align="center" justify="left" class="mb-0">
          <date-request-changer :date="date" @update:day="changeDay($event, false)">
            <div v-if="this.dateFrom !== ''" class="text-h5">Туда</div>
          </date-request-changer>
          <date-request-changer
              v-if="this.dateFrom !== ''"
              :date="this.dateFrom"
              :min="addDays(new Date(formatDateToDatepicker(date)), -1)"
              @update:day="changeDay($event, true)"
          >
            <div class="text-h5">Обратно</div>
          </date-request-changer>
        </v-row>
    -->

    <v-row v-if="loading">
      <v-col>
        <overlay-loader overlay :loading="loading"></overlay-loader>
      </v-col>
    </v-row>

    <v-row v-else-if="searchFound" id="list-head">
      <v-container fluid>
        <v-row>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
          </v-col>
          <v-col cols="12" md="3" class="pr-5">
            <ticket-search-filters-list @drop-filters="dropFilters">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>Цена</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <range-slide-filter
                        v-model="priceRange"
                        side-inputs
                        :max="this.maxPrice"
                        :min="this.minPrice"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Выезд</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter
                        :base="departuresList"
                        v-model="departuresSelected"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Прибытие</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter
                        :base="arrivalsList"
                        v-model="arrivalsSelected"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Время отъезда</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <range-slide-filter
                        v-model="departureTimeRange"
                        side-labels
                        sideLabelsText="ч"
                        :max="1439"
                        :devider="60"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Время прибытия</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <range-slide-filter
                        v-model="arrivalTimeRange"
                        side-labels
                        sideLabelsText="ч"
                        :max="1439"
                        :devider="60"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </ticket-search-filters-list>
          </v-col>
          <v-col v-if="sortedFilteredVariants.length">
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4" class="mx-lg-5">
                <v-select
                  :items="sortItems"
                  color="orange"
                  item-color="orange"
                  outlined
                  label="Сортировать"
                  v-model="sortBy"
                ></v-select>
              </v-col>
            </v-row>
            <train-list-item
              v-for="(variant, i) in sortedFilteredVariants.slice(
                (this.page - 1) * this.perPage,
                (this.page - 1) * this.perPage + this.perPage
              )"
              :key="i"
              :variant="variant"
              :colorIndex="i % 2"
              :passengers="passengersList"
              :depCity="src"
              :arrCity="dest"
              @BookTrain="BookTrain"
            ></train-list-item>

            <v-pagination
              color="secondary"
              v-model="page"
              :length="Math.ceil(sortedFilteredVariants.length / perPage)"
            ></v-pagination>
          </v-col>

          <v-col v-else>
            <v-row class="pt-16 mb-16" justify="center" align="center">
              <v-col class="text-center">
                <v-icon class="text--disabled" style="font-size: 200px"
                  >la-search</v-icon
                >
                <div class="text-h5 text-md-h4 mb-4">
                  Ивините! Результаты не найдены
                </div>
                <div>Попробуйте изменить фильтр</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row v-else class="text-h6 my-16 py-16" justify="center" align="center">
      <v-col class="text-center">
        <v-icon class="text--disabled" style="font-size: 200px"
          >la-search</v-icon
        >
        <div class="text-h5 text-md-h4 mb-5">
          Извините! Мы не нашли билеты на указанный маршрут.
        </div>
        <div>
          <v-btn
            dark
            elevation="5"
            large
            color="orange"
            @click="$router.back()"
          >
            Вернуться
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <error-modal v-model="hasErrors" :error="error"></error-modal>
    <overlay-loader
      overlay
      :loading="filterLoading"
      :absolute="false"
    ></overlay-loader>
  </v-container>
</template>

<script>
import TrainListItem from "@/components/FoundedTicketsPage/TrainListItem";
import ErrorModal from "@/components/UI/ErrorModal";
import FareRemarksModal from "@/components/FoundedTicketsPage/FareRemarksModal";
import DateRequestChanger from "@/components/UI/DateRequestChanger.vue";
import OverlayLoader from "@/components/UI/OverlayLoader";
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat";
import PassengerHelper from "@/mixins/booking/PassengerHelper";
import moment from "moment";
import BookTrain from "@/Pages/BookTrain.vue";
import {format} from "date-fns";
import {mapGetters} from "vuex";
import CheckBoxFilter from "@/components/UI/CheckBoxFilter.vue";
import RangeSlideFilter from "@/components/UI/RangeSlideFilter.vue";
import TicketSearchFiltersList from "@/components/FoundedTicketsPage/TicketSearchFiltersList.vue";

export default {
  name: "TrainsList",
  mixins: [ApiCalls, PassengerHelper, DateFormat],
  components: {
    TicketSearchFiltersList, RangeSlideFilter, CheckBoxFilter,
    FareRemarksModal,
    ErrorModal,
    OverlayLoader,
    TrainListItem,
    DateRequestChanger,
  },
  props: {
    "src": String,
    "dest": String,
    "codeSrc": String,
    "codeDest": String,
    "date": String,
    "adults": Number,
    "kids": Number,
    "bbs": Number,
    "searchId": String,
    "dateFrom": String,
    "depCityText": String,
    "oneway": Boolean,
    "segmentOne": {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      searchFound: false,
      filterLoading: false,
      dialog: false,
      apiVariants: [],
      departures: [],
      arrivals: [],
      backWayDate: "",
      onewayBoolean: (this.onewayBoolean === this.oneway),
      sortItems: [
        {
          text: "Цена (минимальная): по возрастанию",
          value: 0,
        },
        {
          text: "Цена (минимальная): по убыванию",
          value: 1,
        },
        {
          text: "Цена (максимальная): по возрастанию",
          value: 2,
        },
        {
          text: "Цена (максимальная): по убыванию",
          value: 3,
        },
        {
          text: "Время отбытия: по возрастанию",
          value: 4,
        },
        {
          text: "Время отбытия: по убыванию",
          value: 5,
        },
        {
          text: "Время пути: по возрастанию",
          value: 6,
        },
        {
          text: "Время в пути: по убыванию",
          value: 7,
        },
      ],
      sortBy: 0,
      page: 1,
      perPage: 10,
      depTimeIntervals: null,
      arrTimeIntervals: null,
      minPrice: 0,
      maxPrice: 0,
      priceRange: [],
      timeRange: [0, 3600],
      hasErrors: false,
      error: { Code: "", Message: "" },
      segments: [],
      sortedFilteredVariants: [],
      timerFilter: null,
      passengersList: [],
      trainSegment: [],
      //start filtersSets
      departuresSelected: [],
      arrivalsSelected: [],
      departureTimeRange: [0, 1440],
      arrivalTimeRange: [0, 1440],

    };
  },
  async mounted() {
    await this.refreshTickets();
    if (this.$store.state.trainSegments.length > 0) {
      this.trainSegment = this.$store.state.trainSegments
    }
    this.passengersList = this.getPassengersData(
      this.adults,
      this.kids,
      this.bbs
    );
  },
  watch: {
    priceRange: 'applyFilters',
    timeRange: 'applyFilters',
    departuresSelected: 'applyFilters',
    arrivalsSelected: 'applyFilters',
    sortBy: 'applyFilters',
    departureTimeRange: {
      handler() {
        this.applyFilters();
      },
      deep: true
    },
    arrivalTimeRange: {
      handler() {
        this.applyFilters();
      },
      deep: true
    },
    variants() {
      this.page = 1;
    },
    filters: {
      deep: true,
      handler() {
        clearTimeout(this.timerFilter);

        this.timerFilter = setTimeout(() => {
          this.sortedFilteredVariants = this.variants.filter(variant => {
            const departureTime = moment(variant.departureDateTime, "HH:mm");
            const departureMinutes = departureTime.hours() * 60 + departureTime.minutes();

            const isWithinTimeRange = departureMinutes >= this.timeRange[0] * 60 && departureMinutes <= this.timeRange[1] * 60;

            return variant.carGroups.some(group => group.minTicketPrice >= this.priceRange[0] && group.minTicketPrice <= this.priceRange[1]) && isWithinTimeRange;
          });
          this.fakeLoading();
        }, 1000);
      },
    },
    page(n, o) {
      if (n !== o) this.fakeLoading(500, true);
    },
  },
  computed: {
    ...mapGetters(['getTrainSegments', 'getPrice', 'getSeats']),
    departuresList() {
      if (!this.apiVariants) return [];
      const uniqueDepartures = new Set(this.apiVariants.map(variant => variant.passengerDepartureStationName));
      return Array.from(uniqueDepartures).map(name => ({text: name, value: name}));
    },

    arrivalsList() {
      if (!this.apiVariants) return [];
      const uniqueArrivals = new Set(this.apiVariants.map(variant => variant.passengerArrivalStationName));
      return Array.from(uniqueArrivals).map(name => ({text: name, value: name}));
    },
    travelTimes() {
      if (!this.apiVariants) return [];
      return this.apiVariants.map(variant => {
        const departureTime = moment(variant.departureDateTime);
        const arrivalTime = moment(variant.arrivalDateTime);
        const duration = moment.duration(arrivalTime.diff(departureTime));
        const hours = duration.hours();
        const minutes = duration.minutes();
        return `${hours} ч ${minutes} мин`;
      });
    },
    variants() {
      return this.sortedFilteredVariants.sort((v, w) => {
        // Find the lowest minPrice and highest maxPrice within the carGroups of each variant
        const vMinPrices = v.carGroups.map(group => group.minTicketPrice);
        const wMinPrices = w.carGroups.map(group => group.minTicketPrice);
        const vMaxPrices = v.carGroups.map(group => group.maxTicketPrice);
        const wMaxPrices = w.carGroups.map(group => group.maxTicketPrice);

        // Since we are using min and max, we need to make sure the array is not empty
        const vMinPrice = vMinPrices.length ? Math.min(...vMinPrices) : Infinity;
        const wMinPrice = wMinPrices.length ? Math.min(...wMinPrices) : Infinity;
        const vMaxPrice = vMaxPrices.length ? Math.max(...vMaxPrices) : 0;
        const wMaxPrice = wMaxPrices.length ? Math.max(...wMaxPrices) : 0;

        switch (this.sortBy) {
          case 0:
            return vMinPrice - wMinPrice;
          case 1:
            return wMinPrice - vMinPrice;
          case 2:
            return vMaxPrice - wMaxPrice;
          case 3:
            return wMaxPrice - vMaxPrice;
          case 4:
            return moment(v.departureDateTime).valueOf() - moment(w.departureDateTime).valueOf();
          case 5:
            return moment(w.departureDateTime).valueOf() - moment(v.departureDateTime).valueOf();
          case 6:
            let durationV = moment.duration(moment(v.arrivalDateTime).diff(moment(v.departureDateTime)));
            let durationW = moment.duration(moment(w.arrivalDateTime).diff(moment(w.departureDateTime)));
            return durationV.asMinutes() - durationW.asMinutes();
          case 7:
            durationV = moment.duration(moment(v.arrivalDateTime).diff(moment(v.departureDateTime)));
            durationW = moment.duration(moment(w.arrivalDateTime).diff(moment(w.departureDateTime)));
            return durationW.asMinutes() - durationV.asMinutes();
          default:
            return 0;
        }
      });
    },
    depChosen() {
      return this.departures.some((c) => c.selected);
    },
    arrChosen() {
      return this.arrivals.some((c) => c.selected);
    },
    depTimeChosen() {
      return this.depTimeIntervals.some((c) => c.selected);
    },
    arrTimeChosen() {
      return this.arrTimeIntervals.some((c) => c.selected);
    },
    filters() {
      return [
        this.departures,
        this.arrivals,
        this.depTimeIntervals,
        this.arrTimeIntervals,
        this.priceRange,
        this.timeRange,
        this.sortBy,
      ];
    },
  },

  methods: {
    async changeDay(date, isBack) {
      let destinationDate;
      if (this.oneway || (isBack && !this.segmentOne)) {
        destinationDate = date; // Если oneway true или выбран билет туда-обратно и не выбран сегмент обратного билета
      } else {
        destinationDate = this.back; // Если выбран сегмент обратного билета
      }

      await this.$router.push(
          `/trc-list?src=${this.src}&dest=${this.dest}&codeSrc=${this.codeSrc}&codeDest=${this.codeDest}&date=${date}&adults=${this.adults}&kids=${this.kids}&bbs=${this.bbs}&oneway=${this.oneway}`
      );
      this.refreshTickets();
    },
    applyFilters() {
      let filteredVariants = this.apiVariants
          .filter(variant => {
            const isWithinPriceRange = variant.carGroups.some(group =>
                group.minTicketPrice >= this.priceRange[0] && group.minTicketPrice <= this.priceRange[1]
            );

            const departureTime = moment(variant.departureDateTime);
            const arrivalTime = moment(variant.arrivalDateTime);
            const tripDuration = arrivalTime.diff(departureTime, 'minutes');
            const isWithinDurationRange = tripDuration >= this.timeRange[0] * 60 && tripDuration <= this.timeRange[1] * 60;

            const departureMinutes = moment(variant.departureDateTime).hours() * 60 + moment(variant.departureDateTime).minutes();
            const arrivalMinutes = moment(variant.arrivalDateTime).hours() * 60 + moment(variant.arrivalDateTime).minutes();


            const isDepartureTimeSelected = departureMinutes >= this.departureTimeRange[0] && departureMinutes <= this.departureTimeRange[1];
            const isArrivalTimeSelected = arrivalMinutes >= this.arrivalTimeRange[0] && arrivalMinutes <= this.arrivalTimeRange[1];

            const isDepartureSelected = this.departuresSelected.length === 0 || this.departuresSelected.includes(variant.passengerDepartureStationName);
            const isArrivalSelected = this.arrivalsSelected.length === 0 || this.arrivalsSelected.includes(variant.passengerArrivalStationName);

            return isWithinPriceRange && isWithinDurationRange && isDepartureSelected && isArrivalSelected && isDepartureTimeSelected && isArrivalTimeSelected;
          });

      if (this.sortBy === "tripDurationAscending") {
        filteredVariants.sort((a, b) => {
          const durationA = moment.duration(moment(a.arrivalDateTime).diff(moment(a.departureDateTime)));
          const durationB = moment.duration(moment(b.arrivalDateTime).diff(moment(b.departureDateTime)));
          return durationA.asMinutes() - durationB.asMinutes();
        });
      }

      this.sortedFilteredVariants = filteredVariants;
      this.fakeLoading();
    },
    format,
    addTrainSegment(newSegment) {
      this.$store.dispatch('addTrainSegment', newSegment)
      console.log("success save", newSegment)
    },
    removeTrainSegment(index) {
      this.$store.dispatch('removeTrainSegment', index)
    },
    async BookTrain(variant) {
      this.departureDateTime = variant.departureDateTime;
      this.trainNumber = variant.trainNumber;

      try {
        if (this.onewayBoolean) {
          await this.$router.push(
              `/book-train?searchId=${this.searchId}&trainNumber=${this.trainNumber}&departureDateTime=${this.departureDateTime}&adults=${this.adults}&kids=${this.kids}&bbs=${this.bbs}&oneway=${this.onewayBoolean}&dateFrom=0&src=0&dest=0&codeSrc=0&codeDest=0`
          );
        } else {
          this.segmentOne = this.segmentOne || false;
          if (this.segmentOne) {
            this.oneway = true;
          }
          await this.$router.push(
              `/book-train?searchId=${this.searchId}&trainNumber=${this.trainNumber}&departureDateTime=${this.departureDateTime}&adults=${this.adults}&kids=${this.kids}&bbs=${this.bbs}&oneway=${this.oneway}&dateFrom=${this.dateFrom}&src=${this.src}&dest=${this.dest}&codeSrc=${this.codeSrc}&codeDest=${this.codeDest}&segmentOne=${this.segmentOne}`
          );
        }
      } catch (e) {
        console.error(e.toString());
      }
    },
    async refreshTickets() {
      this.loading = true;
      try {
        await this.getTrains();
        console.log(this.searchId)
        this.sortedFilteredVariants = this.variants;
        console.log(this.sortedFilteredVariants)
      } catch (e) {

      } finally {
        this.loading = false;
      }

    },
    timeIntervals() {
      return [
        {
          from: "00:00",
          to: "06:00",
          selected: false,
        },
        {
          from: "06:00",
          to: "12:00",
          selected: false,
        },
        {
          from: "12:00",
          to: "18:00",
          selected: false,
        },
        {
          from: "18:00",
          to: "23:59",
          selected: false,
        },
      ];
    },
    initDate() {
      this.departures = [];
      this.arrivals = [];
      this.searchFound = this.apiVariants.length;
      this.apiVariants.forEach((v) => {
        this.departures.push(v.from);
        this.arrivals.push(v.to);
      });
      this.departures = this.departures.filter(this.unique).map((v) => ({
        text: v,
        selected: false,
      }));

      this.arrivals = this.arrivals.filter(this.unique).map((v) => ({
        text: v,
        selected: false,
      }));

      this.depTimeIntervals = this.timeIntervals();
      this.arrTimeIntervals = this.timeIntervals();

      this.priceRange = [this.minPrice, this.maxPrice];
    },

    unique(value, index, self) {
      return self.indexOf(value) === index;
    },
    dropFilters() {
      this.departures.forEach((e) => (e.selected = false));
      this.arrivals.forEach((e) => (e.selected = false));
      this.depTimeIntervals.forEach((e) => (e.selected = false));
      this.arrTimeIntervals.forEach((e) => (e.selected = false));
      this.timeRange = [0, 3600];
      this.priceRange = [this.minPrice, this.maxPrice];
    },
    fakeLoading(delay = 500, offset = false) {
      this.filterLoading = true;
      setTimeout(() => {
        this.filterLoading = false;
        if (offset)
          this.$vuetify.goTo("#list-head", {
            offset: 100,
          });
      }, delay);
    },
  },
};

</script>

<style scoped></style>
